/* src/components/Spinner.css */
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px; /* Adjust height as needed */
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #3498db; /* Adjust spinner color */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  